import { template as template_030376bc17c44934aa73aae385f0c8ac } from "@ember/template-compiler";
const FKLabel = template_030376bc17c44934aa73aae385f0c8ac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
