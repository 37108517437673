import { template as template_83140e711ae041b2803da2cdba354d07 } from "@ember/template-compiler";
const FKControlMenuContainer = template_83140e711ae041b2803da2cdba354d07(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
