import { template as template_f850a2a9ea174452bf2eabe97729a8eb } from "@ember/template-compiler";
const FKText = template_f850a2a9ea174452bf2eabe97729a8eb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
